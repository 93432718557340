import React from "react";

const PageTitlePortfolio = () => {
  return (
    <section id="page-title">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">Portfolio.</h1>
        </div>

        <div className="description-2">
          <p>
            Zie hier een aantal shoots waarbij ik veel plezier heb gehad. Klik
            op de foto om meer te weten te komen!{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitlePortfolio;
