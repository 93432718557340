import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

const Layout = ({ children, title, desc, image }) => {
  document.body.classList.add("home");

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>{`${title} | PhotosByMaart`}</title>
        <meta
          name="description"
          content={
            desc ||
            `Ik vind het heerlijk om mensen voor de lens te hebben waarbij ongedwongenheid en plezier het uitgangspunt is en mensen blij te maken met het resultaat.`
          }
        />
        <meta property="og:title" content={`${title} | PhotosByMaart`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.photosbymaart.nl" />
        <meta
          property="og:image"
          content={image || "assets/img/og_image.jpg"}
        />
        <meta
          property="og:description"
          content={
            desc ||
            `Ik vind het heerlijk om mensen voor de lens te hebben waarbij ongedwongenheid en plezier het uitgangspunt is en mensen blij te maken met het resultaat.`
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/img/favi/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="assets/img/favi/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="assets/img/favi/favicon-16x16.png"
        />
        <link href="/favicon.ico" rel="shortcut icon" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#eae6df" />
        <meta name="msapplication-TileColor" content="#765a42" />
        <meta name="theme-color" content="#eae6df" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
      </MetaTags>

      <Loading />

      <Header />

      <div>
        {showButton && (
          <button onClick={scrollToTop} className="topBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="#65605a"
              viewBox="0 0 24 24"
              stroke="white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </button>
        )}
        {children}
      </div>

      <Footer />
    </Fragment>
  );
};

export default Layout;
