import React from "react";

const ContactMe = () => {
  return (
    <section id="contact-me" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-md with-pb-md">
          <div className="col-sm-8 col-md-8 col-lg-7 col-xl-7 align-self-center">
            <div className="title-cursive">
              <h2>"Precies zoals jij het wilt."</h2>
            </div>

            <div className="description max-width-1">
              <p>
                Als jij niet een goede locatie weet of wat jij nou eigenlijk
                wilt, kunnen wij samen zoeken naar een goede oplossing die
                voldoet aan al jou eisen. Heb je nog vragen? Contacteer mij dan
                hier.
              </p>
            </div>

            <div className="description max-width-1 spacer m-top-lg no-space">
              <a
                className="btn btn-link btn-line p-0 border-0 min-w-auto text-uppercase before"
                href={process.env.PUBLIC_URL + "/contacts"}
              >
                Contacteer mij
              </a>
            </div>
          </div>

          <div className="col-sm-4 col-md-4 col-lg-5 col-xl-5">
            <div className="img-size-1">
              <img src="assets/img/demo/eigenfoto.png" alt="Contact Me" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactMe;
