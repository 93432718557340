import React from "react";
const PageTitleHome = () => {
  return (
    <section id="page-title" className="block">
      <div className="wrapper">
        <div className="page-title-content">
          <div className="page-title-logo">
            <img src="assets/img/demo/home_camera2.png" alt="Home camera" />
          </div>
          <div className="page-title-img">
            <img src="assets/img/demo/home_camera.png" alt="Home camera" />
          </div>

          <div className="page-title-description">
            <div className="title">
              <h1 className="large">Onvergetelijke momenten.</h1>
            </div>

            <div className="description">
              <p>
                "Ik vind het heerlijk om mensen voor de lens te hebben waarbij
                ongedwongenheid en plezier het uitgangspunt is en mensen blij te
                maken met het resultaat."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitleHome;
