import React from "react";
import Layout from "../layout/layout";

import PageTitleContacts from "../blocks/page-title/PageTitleContacts";
import ContactsList from "../blocks/contacts/ContactsList";
import ContactMe2 from "../blocks/contacts/ContactMe2";

const Contacts = () => {
  document.body.classList.add("page");

  return (
    <Layout
      title="Contact"
      desc={
        "Vul het contactformulier in om mij te contacteren, ook kan je mij een bericht op instagram of facebook sturen."
      }
    >
      <main id="main" className="site-main">
        <PageTitleContacts />

        <div id="page-content" className="spacer p-top-md">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <ContactsList />

                <div id="contact-me-2" className="block spacer p-top-xl">
                  <ContactMe2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Contacts;
