import React from "react";
import Layout from "../layout/layout";
import PageTitleHome from "../blocks/page-title/PageTitleHome";
import AboutServices from "../blocks/about/AboutServices";
import HomePortfolio from "../blocks/home/HomePortfolio";
import AboutMe from "../blocks/home/AboutMe";
import ContactMe from "../blocks/home/ContactMe";
import ContactMe2 from "../blocks/contacts/ContactMe2";

const Home = () => {
  document.body.classList.add("home");

  return (
    <Layout title="Home">
      <div className="content">
        <div className="clearfix">
          <PageTitleHome />

          <AboutMe />

          <div id="services" className="block spacer p-top-xl p-bottom-md">
            <div className="wrapper">
              <div className="title">
                <h2>Wat kan ik vastleggen?</h2>
              </div>

              <AboutServices />
            </div>
          </div>

          <HomePortfolio />

          <ContactMe />

          <section id="contact-me-2" className="block spacer p-top-xl">
            <div className="wrapper">
              <ContactMe2 />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
