import React from "react";

import GalleryHome from "../gallery/GalleryHome";

const HomePortfolio = () => {
  return (
    <section id="porfolio" className="block spacer p-top-xl ">
      <div className="wrapper">
        <div className="title">
          <h2>Mijn Portfolio</h2>
        </div>

        <div className="description max-width-2">
          <p>
            Zie hier een aantal shoots waarbij ik veel plezier heb gehad. Klik
            op de foto om meer te weten te komen! Nog meer foto's zien? Klik dan{" "}
            <a href={process.env.PUBLIC_URL + "/portfolio"}>
              <u>hier</u>
            </a>
            .
          </p>
        </div>

        <div className="spacer p-top-lg">
          <GalleryHome />
        </div>
      </div>
    </section>
  );
};

export default HomePortfolio;
