import React, { Component } from "react";
import axios from "axios";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        name: "",
        email: "",
        message: "",
        formSuccess: false,
      },
      successMessage:
        "Succesvol verzonden! We zullen zo snel mogelijk contact met je opnemen.",
      warningMessage: "Vul alle velden in!",
      errorMessage: "Er is iets fout gegaan, probeer het later opnieuw!",
      alertClass: "",
      responseMessage: "",
      alertTimeout: "",
      delay: 5000,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ formSuccess: false });
    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }

    this.setState({ isSubmitting: true });

    axios
      .post("https://api.byte24.nl/photosByMaart/contact", this.state.values)
      .then((response) => {
        this.setState({ formSuccess: true, name: "", email: "", message: "" });
      })
      .catch((error) => {
        this.callAlert(this.state.errorMessage, "error");
      });
  };

  removeAlert = () => {
    clearTimeout(this.state.alertTimeout);
    this.setState({
      alertTimeout: setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");
        setTimeout(function () {
          element.remove();
        }, 900);
      }, this.state.delay),
    });
  };

  callAlert = (message, type) => {
    if (!document.querySelector("#alert")) {
      if (type === "success") {
        this.setState({ alertClass: "success" });
      }

      if (type === "error") {
        this.setState({ alertClass: "danger" });
      }

      if (type === "warning") {
        this.setState({ alertClass: "warning" });
      }

      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        this.state.alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector("#cf-1");

      element.insertAdjacentHTML("beforeend", alert);

      this.removeAlert();
    }
  };

  handleInputChange = (e) =>
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });

  render() {
    return (
      <form
        onSubmit={this.submitForm}
        method="post"
        action="form.php"
        id="cf-1"
        className="contact-form"
      >
        <div className="form-group form-group-lg">
          {this.state.formSuccess && (
            <div
              className="alert alert-primary"
              role="alert"
              style={{ marginBottom: "30px" }}
            >
              <h4 className="alert-heading">Contactaanvraag verstuurd!</h4>
              <hr />
              <p>
                Bedankt voor je contactaanvraag! Ik zal z.s.m. contact met je
                opnemen.
              </p>
            </div>
          )}

          <p className="input-group gutter-width-md no-space">
            <span className="gutter-width">
              <label className="form-label-lg before after" htmlFor="cf-1-name">
                Naam *
              </label>
              <input
                name="name"
                type="text"
                className="form-lg"
                id="cf-1-name"
                placeholder="Jouw naam"
                value={this.state.values.name}
                onChange={this.handleInputChange}
                required="required"
              />
            </span>

            <span className="gutter-width">
              <label
                className="form-label-lg before after"
                htmlFor="cf-1-email-phone"
              >
                Email *
              </label>
              <input
                name="email"
                type="text"
                className="form-lg"
                id="cf-1-email-phone"
                placeholder="voorbeeld@gmail.com"
                required="required"
                value={this.state.values.email}
                onChange={this.handleInputChange}
              />
            </span>
          </p>
        </div>

        <div className="form-group form-group-lg">
          <label className="form-label-lg before after" htmlFor="cf-1-message">
            Bericht *
          </label>
          <textarea
            name="message"
            className="form-lg"
            id="cf-1-message"
            placeholder="Stel een vraag, of beschrijf jou ideeën..."
            value={this.state.values.message}
            onChange={this.handleInputChange}
            minLength={20}
            required="required"
          ></textarea>
        </div>

        <div className="form-group form-group-lg mb-0 text-center">
          <button
            type="submit"
            className="btn btn-outline-primary text-uppercase"
          >
            Stuur
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
