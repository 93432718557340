import React from "react";

const PageTitleBlog = () => {
  return (
    <section id="page-title">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">Tarieven.</h1>
        </div>

        <div className="description-2">
          <p>
            Ik maak gebruik van een online galerij en wetransfer om de foto's in
            hoge resolutie te versturen. Elke shoot heeft een maximale tijd. Heb
            je nog meer vragen contacteer mij dan{" "}
            <a href={process.env.PUBLIC_URL + "/contacts"}>
              <u>hier</u>
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitleBlog;
