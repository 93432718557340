import React from "react";

const ContactsList = () => {
  return (
    <div id="contacts" className="block">
      <div className="row gutter-width-md with-pb-sm">
        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <div className="contacts-item">
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img src="assets/img/logo/iconCamera.png" alt="Location" />
              </div>
            </div>

            <div className="contacts-item-content">
              <h3 className="contacts-item-title after">Locatie</h3>
              <p className="contacts-item-description">
                <span>Noord-Holland</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <div className="contacts-item">
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img src="assets/img/logo/iconCamera.png" alt="Phone no." />
              </div>
            </div>

            <div className="contacts-item-content">
              <h3 className="contacts-item-title after">
                Email &#38; Telefoonnummer
              </h3>
              <p className="contacts-item-description">
                <a href="mailto:info@photosbymaart.nl">info@photosbymaart.nl</a>
                <br />
                <a href="tel:+31612364983">06 12 36 49 83</a>
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <div className="contacts-item">
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img src="assets/img/logo/iconCamera.png" alt="Email" />
              </div>
            </div>

            <div className="contacts-item-content">
              <h3 className="contacts-item-title after">Socials</h3>
              <p className="contacts-item-description">
                Stuur mij een bericht op{" "}
                <a
                  href="https://www.instagram.com/photosbymaart/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>{" "}
                of{" "}
                <a
                  href="https://m.facebook.com/pages/category/Photography-Videography/PhotosbyMaart-176891107289481/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsList;
