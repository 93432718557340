import React from "react";
import Layout from "../layout/layout";

import PageTitleRates from "../blocks/page-title/PageTitleRates";
import RatesContent from "../blocks/rates/RatesContent";

const Journal = () => {
  document.body.classList.add("rates");

  return (
    <Layout
      title="Tarieven"
      desc={
        "Wat houdt een shoot nou in? Uit een bepaalde tijdsbestek fotograferen komt een bepaalde hoeveelheid foto's. Deze foto's worden in een digitale galerij en op wetransfer met jou gedeeld."
      }
    >
      <main id="main" className="site-main">
        <PageTitleRates />

        <div id="page-content" className="spacer p-top-lg">
          <div className="wrapper">
            <div className="content">
              <div id="rates">
                <RatesContent />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Journal;
