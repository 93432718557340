import React from "react";

const AboutMe = () => {
  return (
    <section id="contact-me" className="block spacer p-top-md">
      <div className="wrapper">
        <div className="row gutter-width-md with-pb-md">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 align-self-center">
            <div className="title-cursive">
              <h2>Wat leuk dat je een kijkje komt nemen op mijn website!</h2>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-6 description">
                <p>
                  Mijn naam is Maartje, ik woon samen met mijn man en twee
                  dochters in Heerhugowaard.
                </p>
                <br />
                <br />
                <p>
                  Naast mijn mooie werk in de zorg is fotografie mijn passie.
                  Het fotograferen zit er al van jongs af aan in. Ik ging niet
                  op vakantie zonder camera en fotorolletjes en kon niet wachten
                  om bij thuiskomst de foto's te ontwikkelen en het resultaat te
                  zien.
                </p>
                <br />
                <br />
                <p>
                  Dat 14 jaar geleden onze oudste dochter werd geboren kocht ik
                  een spiegelreflexcamera. Mijn hobby werd steeds groter. Ik
                  vond het heerlijk om mijn gezin voor de camera te hebben maar
                  ook om vroeg op te staan en in alle rust de zonsopgang en
                  mooie landschappen te fotograferen.
                </p>
                <br />
                <br />
                <p>
                  De laatste paar jaar merkte ik dat steeds meer mensen
                  enthousiast werden van mijn foto's en zo begonnen de aanvragen
                  te rollen.
                </p>
                <br />
                <br />
                <p>
                  Ik vind het heerlijk om mensen voor de lens te hebben waarbij
                  ongedwongenheid en plezier het uitgangspunt is en mensen blij
                  te maken met het resultaat. En hoe leuk is het om hier mijn
                  werk van te maken!{" "}
                </p>
                <br />
                <br />
                <p>
                  Kijk lekker rond op mijn site en wie weet zie ik je snel door
                  mijn lens.
                </p>
                <br />
                <br />
                <p>Liefs Maartje.</p>
                <a
                  className="btn btn-link btn-line p-0 border-0 min-w-auto text-uppercase before spacer p-top-md"
                  href={process.env.PUBLIC_URL + "/contacts"}
                >
                  Contacteer mij
                </a>
              </div>
              <div className="d-none col-6 col-md-5 col-lg-6 d-md-flex align-items-center">
                <div className="img-size-1">
                  <img
                    src="assets/img/portfolio/eigenfoto.webp"
                    alt="Contact Me"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
