import React from "react";
import Layout from "../layout/layout";

import PageTitlePortfolio from "../blocks/page-title/PageTitlePortfolio";
import Gallery from "../blocks/gallery/Gallery";

const Portfolio = () => {
  document.body.classList.add("archive");

  return (
    <Layout
      title="Portfolio"
      desc={
        "Zie hier een aantal shoots van een bruiloft, familieshoots, loveshoots, potretten, bedrijven en recente foto's op mijn instagram."
      }
    >
      <main id="main" className="site-main">
        <PageTitlePortfolio />

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <Gallery />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Portfolio;
