import React, { Fragment } from "react";

const PageTitleBlog = () => {
  return (
    <Fragment>
      <section id="page-title" class="pt-8 pt-md-24 pb-24">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="order-md-1 col-12 col-md-5 align-items-center">
              <div class="mt-16 mt-md-0 mw-sm mw-md-lg mx-auto me-xl-0 ms-xl-auto">
                <h2 class="mb-9 display-5 lh-sm font-heading">Portret</h2>

                <ul className="spacer p-top-sm">
                  <li className="">1 persoon.</li>

                  <li className="">1/1,5 uur fotografie.</li>

                  <li className="">
                    20 foto's in hoge resolutie verstuurd in online galerij en
                    wetransfer.
                  </li>

                  <li className="">€150</li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-6 spacer p-top-md">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/portret16.webp"
                    class="img-fluid mb-4"
                    alt=""
                  />

                  <img
                    src="assets/img/portfolio/portret1.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.3s"
                  />
                </div>

                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/portret14.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.1s"
                  />

                  <img
                    src="assets/img/portfolio/portret4.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.4s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="page-title" class="pt-8 pt-md-24 pb-24">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 align-items-center">
              <div class="mt-16 mt-md-0 mw-sm mw-md-lg mx-auto me-xl-0 ms-xl-auto">
                <h2 class="mb-9 display-5 lh-sm font-heading">Loveshoot</h2>

                <ul className="spacer p-top-sm">
                  <li className="">2 personen.</li>

                  <li className="">1/1,5 uur fotografie.</li>

                  <li className="">
                    25 foto's in hoge resolutie verstuurd in online galerij en
                    wetransfer.
                  </li>

                  <li className="">€170</li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-6 spacer p-top-md">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/loveshoot.webp"
                    class="img-fluid mb-4"
                    alt=""
                  />

                  <img
                    src="assets/img/portfolio/loveshoot4.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.3s"
                  />
                </div>

                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/loveshoot2.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.1s"
                  />

                  <img
                    src="assets/img/portfolio/loveshoot3.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.4s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="page-title" class="pt-8 pt-md-24 pb-24">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="order-md-1 col-12 col-md-5 align-items-center">
              <div class="mt-16 mt-md-0 mw-sm mw-md-lg mx-auto me-xl-0 ms-xl-auto">
                <h2 class="mb-9 display-5 lh-sm font-heading">
                  Families / Gezinsshoot
                </h2>

                <ul className="spacer p-top-sm">
                  <li className="">Van 2 tot 6 personen.</li>
                  <li className="">1/1,5 uur fotografie.</li>
                  <li className="">
                    30 foto's in hoge resolutie verstuurd in online galerij en
                    wetransfer.{" "}
                  </li>
                  <li className="">€200</li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-6 spacer p-top-md">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/familieshoot14.webp"
                    class="img-fluid mb-4"
                    alt=""
                  />

                  <img
                    src="assets/img/portfolio/familieshoot7.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.3s"
                  />
                </div>

                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/familieshoot12.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.1s"
                  />

                  <img
                    src="assets/img/portfolio/familieshoot3.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.4s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="page-title" class="pt-8 pt-md-24 pb-24">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 align-items-center">
              <div class="mt-16 mt-md-0 mw-sm mw-md-lg mx-auto me-xl-0 ms-xl-auto">
                <h2 class="mb-9 display-5 lh-sm font-heading">Familieshoot</h2>

                <ul className="spacer p-top-sm">
                  <li className="">Meer dan 6 personen.</li>

                  <li className="">1,5 uur fotografie.</li>

                  <li className="">
                    40 foto's in hoge resolutie verstuurd in online galerij en
                    wetransfer.
                  </li>

                  <li className="">€230</li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-6  spacer p-top-md">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/familieshoot15.webp"
                    class="img-fluid mb-4"
                    alt=""
                  />

                  <img
                    src="assets/img/portfolio/familieshoot.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.3s"
                  />
                </div>

                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/familieshoot2.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.1s"
                  />

                  <img
                    src="assets/img/portfolio/familieshoot13.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.4s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="page-title" class="pt-8 pt-md-24 pb-24">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="order-md-1 col-12 col-md-5 align-items-center">
              <div class="mt-16 mt-md-0 mw-sm mw-md-lg mx-auto me-xl-0 ms-xl-auto">
                <h2 class="mb-9 display-5 lh-sm font-heading">
                  Bruidsfotografie
                </h2>

                <ul className="spacer p-top-sm">
                  <li className="">Kennismakingsgesprek.</li>
                  <li className="">
                    Foto's in hoge resolutie verstuurd in een online galerij en
                    op USB-stick.
                  </li>
                  <li className="">
                    Optie 1: 6 uur en minimaal 200 foto's voor €1000.{" "}
                  </li>

                  <li className="">
                    Optie 2: 8 uur en minimaal 300 foto's voor €1200.{" "}
                  </li>

                  <li className="">
                    Optie 3: 10 uur en minimaal 400 foto's voor €1400.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-6 spacer p-top-md">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/bruidsfotografie7.webp"
                    class="img-fluid mb-4"
                    alt=""
                  />

                  <img
                    src="assets/img/portfolio/bruidsfotografie5.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.3s"
                  />
                </div>

                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/bruidsfotografie2.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.1s"
                  />

                  <img
                    src="assets/img/portfolio/bruidsfotografie1.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.4s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="page-title" class="pt-8 pt-md-24 pb-24">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 align-items-center">
              <div class="mt-16 mt-md-0 mw-sm mw-md-lg mx-auto me-xl-0 ms-xl-auto">
                <h2 class="mb-9 display-5 lh-sm font-heading">Bedrijfsshoot</h2>

                <p class="mb-9 mw-md-md lh-lg spacer p-top-sm">
                  Heb je foto's nodig voor je website of social media? Ook dat
                  is mogelijk! Vraag mij naar de mogelijkheden.
                </p>

                <a
                  className="btn btn-link btn-line p-0 border-0 min-w-auto text-uppercase before"
                  href={process.env.PUBLIC_URL + "/contacts"}
                >
                  Contacteer mij
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 spacer p-top-md">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/bedrijf5.webp"
                    class="img-fluid mb-4"
                    alt=""
                  />

                  <img
                    src="assets/img/portfolio/bedrijf7.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.3s"
                  />
                </div>

                <div class="col-lg-5 col-6 mb-4">
                  <img
                    src="assets/img/portfolio/bedrijf.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.1s"
                  />

                  <img
                    src="assets/img/portfolio/bedrijf6.webp"
                    class="img-fluid mb-4"
                    alt=""
                    data-wow-delay="0.4s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PageTitleBlog;
